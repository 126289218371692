import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import { count, vatPricing } from '@components/config/helpers';
import { Span } from '@components/Components/Reusables/Typography/P';
import useUserDiscount from '@components/_hooks/useUserDiscount';
import AddToCartButton from '@components/Custom/Buttons/AddToCartButton';

const ProductPriceContainer = dynamic(() => import("@components/Components/Reusables/ProductPriceContainer"))
const ProductInputFooter = dynamic(() => import("@components/Components/ProductComponent/ProductInputFooter"), {
        loading: () => (
            <>
                <div className='d-flex mb-2'>
                    <Skeleton className='mr-2' variant="rectangle" height={35} width={90} />
                    <Skeleton variant="rectangle" height={35} width={90} />
                </div>
            </>
        ),
        ssr: false
    }
)


const CategoryProductPriceSection = (props) => {

    const {
        product, 
        long_term,
        page,
        addToQuoteComponent,
        ...rest
    } = props;

    const { VATFlag, tax } = useSelector((state) => state.globalReducer);
    const {hasProductDiscounts} = useUserDiscount();
    const discount_value = hasProductDiscounts();

    return (
        <>
            <Grid 
                columnSpacing={{
                    xs: 3
                }} 
                rowSpacing={{
                    xs: 1,
                    sm: 0
                }}
                container
            >
                <Grid item xs={12} sm={4} md={12} lg={12} xl={addToQuoteComponent ? 12 : 4} className="align-items-center d-flex">
                    <div>
                        <ProductPriceContainer
                            on_offer={product.on_offer}
                            poa_price={product.poa_price}
                            price_breaks={product.price_breaks}
                            product_price={product.product_price}
                            price={product.price}
                            new_price ={product.new_price}
                            all_in_price={product.all_in_price}
                        />
                        { (discount_value != false ) || (product.on_offer == 'yes' && product.poa_price =='no' && count(product.price_breaks) == 0) ?
                            <div className='position-relative'>
                                <Span  component="span" className="fw-semi-bold strikethrough" sx={{color:'#000', position: 'absolute', bottom: '30px', fontSize: '14px'}}>
                                    {vatPricing(VATFlag, tax, product.product_price, product.all_in_price)}
                                </Span> 
                            </div>
                        : null
                        }
                    </div>
                </Grid>
                <Grid 
                    className="d-flex" 
                    sx={{
                        justifyContent: {
                            xl: 'justify-end',
                            lg: 'space-evenly'
                        }
                    }}
                    item 
                    xs={12}
                    sm={8}
                    md={12} 
                    lg={12} 
                    xl={addToQuoteComponent ? 12 : 8}
                >
                    {
                        addToQuoteComponent != false ? (
                            <AddToCartButton
                                handleQtyChange={addToQuoteComponent} 
                                defaultValue={"0"}
                                product={product}
                            />
                        ) : (
                            <ProductInputFooter
                                page={page}
                                product={product}
                                {...rest}
                            />
                        )
                    }
                </Grid>
            </Grid>
        </>
    )
}

export default CategoryProductPriceSection