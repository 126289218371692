import Box from "@mui/material/Box"
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import dynamic from "next/dynamic";
import TextField from "@mui/material/TextField";
import GlobalButton from "@components/Components/GlobalButton/GlobalButton";
const buttonSx = {
    background: 'none',
    overflow: 'hidden',
    border: 'none',
    pl: '1px',
    pr: '1px',
    width: '10px',
    borderRadius: 6,
    ':hover': {
        backgroundColor: 'lightgrey',
        transition: 'none',
        transform: 'none'
    }
}

export default function AddToCartButton({value = null, defaultValue = null, handleQtyChange, disabled, inc_disabled, dec_disabled, product = null}) {
    return (
        <Box
            className="mb-2"
            sx={{ 
                display: 'flex', 
                alignItems: 'center',
                borderRadius: '20px',
                border: '1px solid var(--color-primary)',
                width: '185px'
            }}
        >
        <GlobalButton 
            disabled={dec_disabled || disabled}
            default_classes=""
            aria-label="Decrease qty" 
            onClick={(e) => handleQtyChange(e, 'subtract', product)}
            sx={{ 
                order: 1,
                ...buttonSx,
                width: '25px',
                flex: 1,
            }}
            size="large"
            >
            <RemoveIcon />
                
            </GlobalButton>

            <TextField 
                disabled={disabled}
                aria-label="Product qty"
                size="small"
                min={1} 
                id={`product-${product?.product_id || '0'}`} 
                sx={{
                    borderColor: 'transparent',
                    flex: 1,
                    'input': {
                        flex: 1,
                        width: '80px',
                        padding: '0.25rem',
                        textAlign: 'center',
                        padding: 0,
                        fontWeight: 'bold',
                        height: '100%',
                        '& .Mui-focused': {
                            border: 'none',
                            background: 'none'
                        }
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                        borderColor: 'transparent', // Removes border
                        },
                        '&:hover fieldset': {
                        borderColor: 'transparent', // Removes border on hover
                        },
                        '&.Mui-focused fieldset': {
                        borderColor: 'transparent', // Removes border on focus
                        },
                    },
                    background: 'none',
                    order: 2, 
                    textAlign: 'center', 
                    // width: isMobile ? '40px' : '60px', 
                    width: {
                        xs: '40px',
                        md: '60px'
                    },
                    borderRadius:5, 
                    textAlign: 'center'
                }} 
                type="text" 
                {...(value != null
                    ? { value } 
                    : { defaultValue })}
            />
            <GlobalButton 
                disabled={inc_disabled || disabled}
                default_classes=""
                aria-label="Increase qty" 
                onClick={(e) => handleQtyChange(e, 'add', product)}
                size="small"
                sx={{ 
                    order: 3,
                    ...buttonSx
                }}
                >
                <AddIcon />
            </GlobalButton>
        </Box>
    )
}
